
































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    InsCopyright: () => import('@/components/business/footer/InsCopyright.vue')
  }
})
export default class InsFooterLayout extends Vue {
  content: string = '';
  toUrl (n) {
    if (!n.IsNewWin && n.Url) {
      window.location.href = n.Url;
    } else if (n.IsNewWin && n.Url) {
      window.open(n.Url);
    }
  }

  To (n) {
    return n.Type === 1
      ? '/cms/catDetail/' + n.Value.Id
      : n.Type === 2
        ? '/cms/content/' + n.Value.Id
        : n.Type === 3
          ? '/regnpay/form/' + n.Value.Id
          : '/';
  }

  getContent () {
    this.$Api.cms.getContentByDevice({ key: 'bottom_contact', IsMobile: false }).then(result => {
      this.content = result.CMS;
    });
  }

  mounted () {
    this.getContent();
  }
}
